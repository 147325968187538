<template>
  <div class="case">
    <div class="flex flex-column align-items-center justify-content-center title" style="margin-bottom: 50px">
      <div class="case-title1">
        {{ dictTitle1[active] }}
      </div>
      <div class="case-title2">
        {{ dictTitle2[active] }}
      </div>
    </div>
    <div class="content flex flex-warp">
      <div v-for="(item,index) in showDataList.slice((pageNum - 1) * pageSize, pageNum * pageSize)"
           :style="{'background-image': 'url(' + item.imgUrl + ')'}"
           @mouseover="selectStyle(index) " @mouseout="outStyle(index)" :target="'item'+index" :key="index"
           :class="`img-list${index}`" class="img-list flex align-items-center justify-content-center">
        <div class="title flex align-items-center justify-content-center">{{ item.title }}</div>
      </div>
    </div>
    <div class="flex justify-content-center footer">
      <div class="flex justify-content-start align-items-center">
        <img src="../../../assets/images/News/prev.png" @click="prev" width="50%"/>
      </div>
      <div class="flex justify-content-center align-items-end">
        <div class="out-pageNum">
          <div :style="{transform: `translateY(${(pageNum-1)*-20}px)`}" class="flex flex-column num-content">
            <div v-for="item in totalPageNum" :key="item" class="pageNum">{{ getNum(item) }}</div>
          </div>
        </div>
        <div> /</div>
        <div class="totalPageNum">{{ getNum(totalPageNum) }}</div>
      </div>
      <div class="flex justify-content-end align-items-center">
        <img src="../../../assets/images/News/next.png" @click="next" width="50%"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view2",
  props: {
    active: {}
  },
  data() {
    return {
      pageSize: 9,
      pageNum: 1,
      dictTitle1: {
        0: '部分案例',
        1: '家禽案例',
        2: '家畜案例',
        3: '其他案例',
      },
      dictTitle2: {
        0: 'PART CASE',
        1: 'POULTRY CASE',
        2: 'PIG CASE',
        3: 'OTHER CASE',
      },
      dataList: [{
        title: '山西大象智慧养殖解决方案',
        imgUrl: require('../../../assets/images/Case/img/Tyso.png'),
        type: 1
      }, {
        title: '山东荣达养鸭场智慧养殖解决方案',
        imgUrl: require('../../../assets/images/Case/img/RD.png'),
        type: 1
      }, {
        title: '安徽荣达300万蛋鸡大数据平台开发项目',
        imgUrl: require('../../../assets/images/Case/img/RD300.png'),
        type: 1
      }, {
        title: '广西力源智慧养殖解决方案',
        imgUrl: require('../../../assets/images/Case/img/gxly.png'),
        type: 1
      }, {
        title: '海阳鼎立数字化平台建设项目',
        imgUrl: require('../../../assets/images/Case/img/hydl.png'),
        type: 1
      }, {
        title: '山东亚太中慧集团',
        imgUrl: require('../../../assets/images/Case/img/hdjt.png'),
        type: 1
      }, {
        title: '东南亚客户项目',
        imgUrl: require('../../../assets/images/Case/img/zonghui.png'),
        type: 1
      }, {
        title: '海大集团海大智慧养殖大数据平台',
        imgUrl: require('../../../assets/images/Case/img/dny.png'),
        type: 2
      }, {
        title: 'TYSON物联网系统升级改造',
        imgUrl: require('../../../assets/images/Case/img/tyson.png'),
        type: 1
      }, {
        title: '广西农垦集团生物安全防护系统',
        imgUrl: require('../../../assets/images/Case/img/gxnk.png'),
        type: 1
      }, {
        title: '东方希望集团',
        imgUrl: require('../../../assets/images/Case/img/dfxw.png'),
        type: 2
      }, {
        title: '新希望集团',
        imgUrl: require('../../../assets/images/Case/img/xxwjt.png'),
        type: 2
      },{
        title: '中红三融项目',
        imgUrl: require('../../../assets/images/Case/img/zhsr.png'),
        type: 1
      },{
        title: '健加乐鸭业',
        imgUrl: require('../../../assets/images/Case/img/jjl.png'),
        type: 1
      },{
        title: '亚太中慧许道口场项目',
        imgUrl: require('../../../assets/images/Case/img/ytzhxdk.png'),
        type: 1
      },{
        title: '杭州土哥农牧养殖基地',
        imgUrl: require('../../../assets/images/Case/img/tg.png'),
        type: 2
      },{
        title: '海大集团项目',
        imgUrl: require('../../../assets/images/Case/img/hd2.png'),
        type: 2
      },{
        title: '福建南方牧业',
        imgUrl: require('../../../assets/images/Case/img/fjnf.png'),
        type: 2
      },{
        title: '东方希望志丹项目',
        imgUrl: require('../../../assets/images/Case/img/dfxwzd.png'),
        type: 2
      },{
        title: '东方希望竹峪项目',
        imgUrl: require('../../../assets/images/Case/img/dfxwzy.png'),
        type: 2
      }],
      timeout: null,
    }
  },
  computed: {
    showDataList() {
      if (this.active) {
        return this.dataList.filter(item => item.type == this.active)
      } else {
        return this.dataList
      }
    },
    totalPageNum() {
      let num = 0
      if ((this.showDataList.length % this.pageSize) == 0) {
        num = parseInt(this.showDataList.length / this.pageSize)
      } else {
        num = parseInt(this.showDataList.length / this.pageSize) + 1
      }
      return num
    }
  },
  watch: {
    active() {
      this.pageNum = 1
    }
  },
  mounted() {
  },
  methods: {
    getNum(num) {
      let value = 0
      if (num < 10) {
        value = "0" + num
        return value
      } else {
        value = num
        return value
      }
    },
    prev() {
      if (this.pageNum != 1) {
        this.pageNum = parseInt(this.pageNum) - 1
      }
    },
    next() {
      if (this.pageNum != this.totalPageNum) {
        this.pageNum = parseInt(this.pageNum) + 1
      }
    },
    selectStyle(index) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = window.setTimeout(() => {
        let item = document.querySelector('.img-list' + index)
        let item2 = undefined
        let item3 = undefined
        item.style.width = 'calc((100% - 80px) / 2)'
        if (index % 3 == 0) {
          item2 = document.querySelector('.img-list' + (index + 1))
          item3 = document.querySelector('.img-list' + (index + 2))
        }
        if (index % 3 == 1) {
          item2 = document.querySelector('.img-list' + (index - 1))
          item3 = document.querySelector('.img-list' + (index + 1))
        }
        if (index % 3 == 2) {
          item2 = document.querySelector('.img-list' + (index - 1))
          item3 = document.querySelector('.img-list' + (index - 2))
        }
        item2.style.width = 'calc((100% - 80px) / 4)'
        item3.style.width = 'calc((100% - 80px) / 4)'
        this.timeout = null
      }, 350)
    },
    outStyle(index) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      let item = document.querySelector('.img-list' + index)
      let item2 = undefined
      let item3 = undefined
      item.style.width = 'calc((100% - 80px) / 3)'
      if (index % 3 == 0) {
        item2 = document.querySelector('.img-list' + (index + 1))
        item3 = document.querySelector('.img-list' + (index + 2))
      }
      if (index % 3 == 1) {
        item2 = document.querySelector('.img-list' + (index - 1))
        item3 = document.querySelector('.img-list' + (index + 1))
      }
      if (index % 3 == 2) {
        item2 = document.querySelector('.img-list' + (index - 1))
        item3 = document.querySelector('.img-list' + (index - 2))
      }
      item2.style.width = 'calc((100% - 80px) / 3)'
      item3.style.width = 'calc((100% - 80px) / 3)'
    }

  }
}
</script>

<style lang="scss" scoped>
.case-title1 {
  font-size: 36px;
  font-weight: bold;
  color: #18933B;
  line-height: 36px;
}

.case-title2 {
  font-size: 16px;
  font-family: Inter V-Semi Bold, Inter V;
  color: #18933B;
  line-height: 28px;
}

.case {
  padding-top: 100px;
  margin-bottom: 30px;

  .content {
    gap: 40px;
    @for $i from 0 through 9 {
      .img-list#{$i} {
        position: relative;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: 724px 411px;
        width: calc((100% - 80px) / 3);
        transition: all 0.3s;
        height: 330px;

        &:hover > .title {
          opacity: 1;
          box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16);
        }

        .title {
          position: absolute;
          bottom: 0;
          background: rgba(0, 0, 0, 0.4);
          width: 100%;
          height: 60px;
          color: #FFFFFF;
          font-size: 18px;
          opacity: 0;
          transition: all 0.3s;
        }
      }
    }
  }

  .footer {
    margin-top: 30px;

    .out-pageNum {
      height: 21px;
      width: 20px;
      overflow-y: hidden;

      .num-content {
        transition: all 0.7s;

        .pageNum {
          height: 20px;
          font-size: 16px;
          color: #333333;
        }
      }

    }

    .totalPageNum {
      font-size: 12px;
      color: #999999;
    }
  }
}

</style>
