<template>
  <div class="app-container">
    <div style="width: 100%">
      <img src="../../assets/images/Case/img/casebgimg.png" width="100%">
    </div>
    <div class="flex justify-content-center flex-warp">
      <div class="content">
        <div class="cont">
          <el-col :lg="13">
            <div class=" top-bar-menu-item flex align-items-center justify-content-around"
                 style="width: 100%;">
<!--              <div class="top-bar-menu-item-title pointer" @click="activeItem(0)"-->
<!--                   :class="{'top-bar-menu-item-title-active': active == 0}">{{ $t('transfer.t', {t: '部分案例'}) }}-->
<!--              </div>-->
              <div class="top-bar-menu-item-title pointer" @click="activeItem(1)"
                   :class="{'top-bar-menu-item-title-active': active == 1}">{{ $t('transfer.t', {t: '家禽案例'}) }}
              </div>
              <div class="top-bar-menu-item-title pointer" @click="activeItem(2)"
                   :class="{'top-bar-menu-item-title-active': active == 2}">{{ $t('transfer.t', {t: '家畜案例'}) }}
              </div>
              <div class="top-bar-menu-item-title pointer" @click="activeItem(3)"
                   :class="{'top-bar-menu-item-title-active': active == 3}">{{ $t('transfer.t', {t: '其他案例'}) }}
              </div>
            </div>
          </el-col>
        </div>
        <transition name="el-fade-in-linear">
          <div style="width: 100%">
            <view2 :active="active"></view2>
          </div>
        </transition>
      </div>
    </div>
    <div class="mt10">
      <bottom />
    </div>
  </div>
</template>

<script>
import view2 from "@/views/Case/component/view2";
import bottom from "@/components/bottom";

export default {
  name: "index",
  components: {
    view2,
    bottom
  },
  data() {
    return {
      active: 1
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          let that = this;
          if (that.$route?.query?.active || that.$route?.query?.active == 0) {
            window.scrollTo(0, 0);
            this.active = that.$route?.query?.active
          }
        });
      },
      immediate: true
    },
  },
  mounted() {
  },
  methods: {
    activeItem(val) {
      this.active = val
    },

  }
}
</script>

<style lang="scss" scoped>
.content {
  min-width: 1200px;
  width: 1440px;
  position: relative;

  .cont {
    width: 100%;
    position: absolute;
    top: -30px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    z-index: 3000;

    .top-bar-menu-item-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 20px;
      position: relative;
      height: 50px;

      .child {
        padding-left: 20px;
        position: absolute;
        top: 105%;
        background-color: #fff;
        width: 230%;
        color: #999999;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      }

      &:hover {
        color: #18933B;
      }
    }

    .top-bar-menu-item-title-active {
      color: #18933B;
      border-bottom: 2px solid #18933B;
    }
  }
}
</style>
